import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Conditions from '../components/Conditions'

const assets = {
  support: require('../resources/img/about.png'),
  support_email: require('../resources/img/support_email.svg'),
  support_sales: require('../resources/img/support_sales.svg'),
  support_call: require('../resources/img/support_call.svg'),
  support_whatsapp: require('../resources/img/support_whatsapp.svg')
}


export default () => (
  <Layout page="apropos" className="distort-blue">

    <PageHero title="Conditions d'utilisation"
              content={
                <div>
                  Nous vous recommandons de lire attentivement les termes du présent Contrat sur les Conditions d'utilisation
                  des services de Vitepay avant de l'accepter.Il vous appartient de comprendre et de respecter toutes les lois, règles et réglementations
                  qui peuvent vous être applicables en rapport avec votre utilisation de Vitepay.
                </div>
              }/>

    <p>&nbsp;</p>

    <p>&nbsp;</p>

    <div className="clearfix container mt-5 mb-5">
      <Conditions />
    </div>

  </Layout>
)


const ContactBlock = styled.div`
  background: #FFF;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 1px 6px rgba(0,0,0,0.05);
  margin-top: 60px;
  margin-bottom: 60px;

  img {
    width: 48px;
    margin-bottom: 16px;
  }
  h4 {
    margin-bottom: 15px;
  }
  
  p {
    color: #565758;
    font-size: 0.95em;
  }
  
  a {
    font-size: 0.9em;
  }
`

const APS = styled.div` 
  h3 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: rgba(26,26,26,.9);
    font-size: 13px;
  }
  
  p {
    color: #5c5c5c;
    padding: 20px 30px 20px 0;  
    text-align: justify;
    
    a {
      font-weight: 500;
    }
    
    strong {
      font-weight: 500;
    }
    
  }
  
`
